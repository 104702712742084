.wrapper {
    width: 100%;
}

.disabled {
    pointer-events: none;
}

.label {
    margin-bottom: 5px;
}

.select {
    width: 100%;
    height: 50px;
    padding: 3px;
    border-radius: 5px;
    background-color: #534F4F;
    position: relative;
}

.select select {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: #272525;
    padding: 0 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select select::-ms-expand {
    display: none;
}

.select svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
    width: 10px;
    height: 6px;
}