.pickRandom {
    width: 270px;
    height: 30px;
    border-radius: 5px;
    background: #534F4F;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15), 0 -1px 0 0 rgba(0, 0, 0, 0.50), 0 0 1px 0 rgba(255, 255, 255, 0.30), 0 0px 1px 0 rgba(255, 255, 255, 0.30), 0 0 1px 0 rgba(255, 255, 255, 0.50);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #01D501;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    transition: all .2s ease;
}

.pickRandom svg path {
    transition: all .2s ease;
}

.pickRandom:active, .pickRandom:disabled {
    color: #fff;
}

.pickRandom:active svg path, .pickRandom:disabled svg path {
    fill: #fff;
    stroke: #fff;
}
