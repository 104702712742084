.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  height: 40px;
}

.wrapper > svg {
  width: 10px;
  height: 6px;
  margin-top: -3px;
  margin-bottom: 7px;
  display: none;
}

.wrapper.active > svg {
  display: block;
}

.wrapper > svg path {
  fill: #01D501;
}

.odds {
  height: 30px;
  min-width: 100%;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-37px);
  display: none;
  transition: all .3s ease;
}

.wrapper.active .odds {
  display: flex;
}

.overlay {
  position: absolute;
  height: 30px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(90deg, #272525 0%, rgba(39, 37, 37, 0.00) 100%);
}

.odds__item {
  min-width: 74px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  span {
    position: relative;
    z-index: 1;
    font-size: 14px;
  }

  &.next {
    svg path {
      fill: #3A3737;
      stroke: #3A3737;
    }
  }

  &.current {
    position: relative;
    z-index: 3;

    svg path {
      fill: #272525;
      stroke: #01D501;
    }
  }
}