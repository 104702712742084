.win {
    background: rgba(27, 27, 27, 0.70);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;
    opacity: 0;
    pointer-events: none;
}

.win_active {
    opacity: 1;
    pointer-events: all;
}

.win > p {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #FF9C00;
    margin-top: -25px;
}

.win span {
    color: #fff;
}

.win img {
    max-width: 100%;
}
