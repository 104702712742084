.wrapper {
    background-color: #272525;
    height: 435px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.grid {
    width: 270px;
    margin: 0 auto;
    display: grid;
    grid-template: repeat(5, 55px) / repeat(5, 1fr);
    gap: 5px;

    button {
        align-self: end;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: #494545;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 50px;
            border-radius: inherit;
            background: linear-gradient(135deg, #3A3737 0%, #484848 100%);
            box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.25) inset, 0 1px 1px 0 rgba(255, 255, 255, 0.20) inset;
            z-index: 0;
        }

        .click {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 5px;
            left: 5px;
            border-radius: inherit;
            border: 3px solid #3A3737;
            box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.10) inset, -1px -1px 1px 0 rgba(255, 255, 255, 0.10) inset;
            z-index: 1;
        }

        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: inherit;
            background: linear-gradient(135deg, #0075B5 0%, #00CEFC 100%), linear-gradient(135deg, #3A3737 0%, #484848 100%);
            box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.25) inset, 0 1px 1px 0 rgba(255, 255, 255, 0.20) inset;
            opacity: 0;
            pointer-events: none;
            transition: all .3s ease;
        }

        &.selected {
            height: 50px;

            .click, &::after {
                opacity: 0;
                pointer-events: none;
            }

            .bg {
                opacity: 1;
                pointer-events: auto;
                display: flex;
                align-items: center;
                justify-content: center;

                &.bomb::after {
                    content: url("../../../assets/icons/bomb.png");
                }

                &.coin::after {
                    content: url("../../../assets/icons/coin.png");
                }

                &.crash::after {
                    content: url("../../../assets/icons/crash.png");
                }
            }

        }
    }
}